/* eslint-disable max-len */
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import FooterDailyFreeCreditEn from './footer/FooterDailyFreeCreditEn';
import FooterDefaultEn from './footer/FooterDefaultEn';
import FooterDailyFreeCreditPt from './footer/FooterDailyFreeCreditPt';
import FooterJackpotEn from './footer/FooterJackpotEn';
import FooterJackpotPt from './footer/FooterJackpotPt';
import FooterDefaultPt from './footer/FooterDefaultPt';
import FooterPromotionsEn from './footer/FooterPromotionsEn';
import FooterPromotionsPt from './footer/FooterPromotionsPt';
import FooterSlotsEn from './footer/FooterSlotsEn';
import FooterSlotsPt from './footer/FooterSlotsPt';

const footerComponents = {
  'en-BR': {
    '/daily-free-credit': FooterDailyFreeCreditEn,
    '/jackpot': FooterJackpotEn,
    '/promotions': FooterPromotionsEn,
    '/[category]': FooterSlotsEn,
    default: FooterDefaultEn,
  },
  'pt-BR': {
    '/daily-free-credit': FooterDailyFreeCreditPt,
    '/jackpot': FooterJackpotPt,
    '/promotions': FooterPromotionsPt,
    '/[category]': FooterSlotsPt,
    default: FooterDefaultPt,
  },
};

function Footer() {
  const contentRef = useRef(null);
  const router = useRouter();
  const [expanded, setExpanded] = useState(false);
  const { i18n } = useTranslation();
  const toggleContent = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const content = contentRef.current;

    if (content) {
      if (!expanded) {
        content.style.maxHeight = '100px';
        content.setAttribute('aria-expanded', 'false');
      } else {
        content.style.maxHeight = `${content.scrollHeight}px`;
        content.setAttribute('aria-expanded', 'true');
      }
    }
  }, [expanded]);

  useEffect(() => {
    const handleLanguageChange = () => {
      setExpanded(false);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const renderFooterComponent = () => {
    const currentLanguage = i18n.language || 'en';
    const FooterComponent = footerComponents[currentLanguage]?.[router.pathname] || footerComponents[currentLanguage]?.default;

    if (FooterComponent) {
      return <FooterComponent contentRef={contentRef} />;
    }
    return null;
  };

  return (
    <div className='pt-2 md:pt-2 pb-16 md:pb-6 px-3 md:px-2'>
      {renderFooterComponent()}

      <button className='button-secondary py-1 px-2 text-sm mt-1' onClick={toggleContent}>
        {expanded ? 'Read Less' : 'Read More'}
      </button>
    </div>
  );
}

export default Footer;
